<template>
  <div class="flex align-items-center justify-content-center min-h-screen">
    <div
      class="surface-card p-4 shadow-1 border-round w-full sm:w-9 md:w-8 lg:w-6">
      <div class="text-center">
        <div class="error-code">{{ code }}</div>
        <div class="error-code-message">{{ msg[code] }}</div>
        <router-link to="/" class="link-voltar">Voltar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorView',

  props: {
    code: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      msg: {
        401: 'Você não possui permissão para acessar essa página.',
        403: 'Atenção! Acesso não autorizado. Tente fazer o login novamente.',
        404: 'Desculpe, a página que você está procurando não foi encontrada.',
      },
    }
  },

  mounted() {
    if (this.code == 401) {
      this.$auth.logout()
    }
  },
}
</script>

<style scoped>
.error-code {
  font-size: 6rem;
  font-weight: 900;
}

.error-code-message {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 10px 0px;
}

.link-voltar {
  font-size: 1.2rem;
}
</style>
